import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

      <div className="page_header">
         <h2 className="sub_title">20 X 20 / 下半場</h2>
         <h1 className="main_title">黃姍姍</h1>
      </div>


      <article className="page_content blog_single_post_content">

        <section className="row_area_s">
          <div className="column_5">
              <div className="context">
                <p>策展人黃姍姍以身為城市住民與地球公民的身份來回應今日的議題。首先透過生活中的例子告訴我們生活的行為會影響周遭的人，也會影響到生活的環境。她認為好的公共藝術，創作的同理心與公德心遠比起其表現形式來地更加重要，是為公共性。若是缺乏公共性，就只是存在於公共空間的物件，並沒有公共價值。</p>
                <p>黃姍姍舉丹麥藝術家Olafur Eliasson在2015年巴黎氣候變遷高峰會時的作品──<a href="https://olafureliasson.net/archive/artwork/WEK109190/ice-watch" target="_blank">Ice Watch</a> ──為例。Eliasson將 12塊因地球暖化而碎裂的大冰塊排置於巴黎廣場成鐘面的樣貌，人們可以接觸到平時碰觸不到的冰塊，聽到冰塊溶解的聲音。這件作品具象化地球緩化的事實，提醒我們人類的行為是如何影響環境。</p>
                <p className="onset_right_1">她認為公共藝術是關懷我們共同的環境做為出發點，不管是我們的社區或是我們的地球。我們不應該以藝術之名破壞環境或是傷害到他人權益，她提出「不作為也是一種藝術」做為回應台灣缺乏公共性、破壞環境的公共藝術。 </p>
              </div>
          </div>
        </section>

      </article>

        <div className="page_footer">
          <Link to="/blog/post_1_8" className="next_page">
            <div className="next_title">李萬鏗</div>
            <div className="next_owner">20 X 20 / 下半場</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
